import _ from 'lodash';
import React from 'react';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useStack } from '../hooks/useStack';
import { classNames } from '../utils';

SwiperCore.use([Navigation]);

const TestimonialSection = (props) => {
  let section = _.get(props, 'section', null);

  const [sectionRef, sectionStyle] = useStack();

  return (
    <section
      ref={sectionRef}
      className={classNames('section', 'hero', 'bg-gray')}
      style={{ ...sectionStyle, minHeight: '100vh' }}
    >
      <div className="container container--lg">
        <div
          className={classNames('flex', 'flex--middle', 'flex--center', {
            'align-center': _.get(section, 'align', null) === 'center',
            'align-right': _.get(section, 'align', null) === 'right',
          })}
        >
          <div className="cell section__body">
            {_.get(section, 'title', null) && (
              <h1
                className={classNames('section__title', {
                  'section__title--center':
                    _.get(section, 'align', null) === 'center',
                  'section__title--right':
                    _.get(section, 'align', null) === 'right',
                })}
              >
                {_.get(section, 'title', null)}
              </h1>
            )}
            <div className="testimonials">
              <Swiper spaceBetween={50} navigation loop centeredSlides>
                {_.get(section, 'testimonials', null) &&
                  _.get(section, 'testimonials', null).map(
                    (testimonial, index) => (
                      <SwiperSlide key={index}>
                        <div
                          className={classNames(
                            'testimonial',
                            (index + 1) % 2 === 0 && 'testimonial--secondary',
                            (index + 1) % 3 === 0 && 'testimonial--tertiary',
                          )}
                        >
                          <div className="testimonial__header">
                            <div className="testimonial__title">
                              {testimonial.name}
                            </div>
                            <div className="testimonial__subtitle">
                              {testimonial.info}
                            </div>
                          </div>
                          <div className="testimonial__content">
                            {testimonial.text}
                          </div>
                        </div>
                      </SwiperSlide>
                    ),
                  )}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TestimonialSection;
