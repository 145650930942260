import _ from 'lodash';
import React from 'react';
import { useStack } from '../hooks/useStack';
import { classNames } from '../utils';

const QuoteSection = (props) => {
  let section = _.get(props, 'section', null);
  const background = _.get(section, 'background', null);

  const [sectionRef, sectionStyle] = useStack();

  return (
    <section
      ref={sectionRef}
      style={sectionStyle}
      className={classNames(
        'section',
        'hero',
        background && `bg-${background}`,
        background && `bg-${background}--wow`,
      )}
    >
      <div
        className="container container--lg position-relative"
        style={{ flex: 1, display: 'flex', justifyContent: 'center' }}
      >
        <div className={classNames('flex', 'flex--middle', 'flex--center')}>
          <div className="cell section__body">
            {_.get(section, 'title', null) && (
              <div className="quote">
                <div className="quote__title">
                  {_.get(section, 'title', null)}
                </div>
                {_.get(section, 'author', null) && (
                  <div className="quote__author">
                    {_.get(section, 'author', null)}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        {_.get(section, 'footer', null) && (
          <div className="footer-disclaimer">
            <div>
              website by:{' '}
              <a
                href="https://esoftware.solutions"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: 'var(--grey)' }}
              >
                eSoftware Solutions
              </a>
            </div>
            <div>
              {_.get(section, 'footer', null)}, {new Date().getFullYear()}
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default QuoteSection;
