import React from 'react';
import _ from 'lodash';

import { classNames, toStyleObj, withPrefix } from '../utils';
import SectionActions from './SectionActions';
import { useStack } from '../hooks/useStack';

const HeroSection = (props) => {
  let section = _.get(props, 'section', null);
  let background = _.get(section, 'background', null);
  let background_color = _.get(background, 'background_color', null) || 'white';
  let background_opacity_pct =
    _.get(background, 'background_image_opacity', null) || 100;
  let background_opacity = background_opacity_pct * 0.01;
  let background_size =
    _.get(background, 'background_image_size', null) || 'cover';
  let background_repeat =
    _.get(background, 'background_image_repeat', null) || 'no-repeat';

  const [sectionRef, sectionStyle] = useStack();

  return (
    <section
      ref={sectionRef}
      style={sectionStyle}
      className={classNames('section', 'hero', 'position-static', {
        'bg-image':
          _.get(section, 'has_background', null) &&
          _.get(background, 'background_image', null),
        'inverse bg-blue':
          _.get(section, 'has_background', null) && background_color === 'blue',
        'bg-gray':
          _.get(section, 'has_background', null) && background_color === 'gray',
        'section--padding':
          _.get(section, 'has_background', null) ||
          _.get(section, 'image', null),
      })}
    >
      {_.get(section, 'has_background', null) &&
        _.get(background, 'background_image', null) && (
          <div
            className="bg-image__image"
            style={toStyleObj(
              "background-image: url('" +
                withPrefix(_.get(background, 'background_image', null)) +
                "'); opacity: " +
                background_opacity +
                '; background-size: ' +
                background_size +
                '; background-repeat: ' +
                background_repeat,
            )}
          />
        )}
      <div className="container container--lg">
        <div
          className={classNames('flex', 'flex--middle', 'flex--center', {
            'align-center': _.get(section, 'align', null) === 'center',
            'align-right': _.get(section, 'align', null) === 'right',
          })}
        >
          {_.get(section, 'image', null) && (
            <div
              className={classNames(
                'cell',
                'section__media',
                {
                  'section__media--right':
                    _.get(section, 'image_position', null) === 'right',
                  'section__media--small': _.get(section, 'image_small', null),
                },
                _.get(section, 'imageClass'),
              )}
            >
              <img
                src={withPrefix(_.get(section, 'image', null))}
                alt={_.get(section, 'title', null)}
              />
            </div>
          )}
          <div className="cell section__body">
            {_.get(section, 'title', null) && (
              <h1
                className={classNames('section__title', {
                  'section__title--center':
                    _.get(section, 'align', null) === 'center',
                  'section__title--right':
                    _.get(section, 'align', null) === 'right',
                })}
                dangerouslySetInnerHTML={{__html: _.get(section, 'title', null)}}
              />
            )}
            {_.get(section, 'subtitle', null) && (
              <div className="section__copy section__copy--uppercase">
                <p>{_.get(section, 'subtitle', null)}</p>
              </div>
            )}
            {_.get(section, 'actions', null) && (
              <div className="section__actions btn-group">
                <SectionActions
                  {...props}
                  actions={_.get(section, 'actions', null)}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
