import _ from "lodash";
import React from "react";
import { useStack } from "../hooks/useStack";
import { htmlToReact } from "../utils";
import $ from "jquery";

const ContactSection = (props) => {
  const [sectionRef, sectionStyle] = useStack();

  const handleSubmit = (e) => {
    e.preventDefault();
    let myForm = document.getElementById("contactForm");
    let formData = new FormData(myForm);
    fetch("https://wendy-web.netlify.app/contact/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => $("#thanks").slideDown(), $("#contactForm").slideUp())
      .catch(() => $("#thanks_error").slideDown(), $("#contactForm").slideUp());
  };

  return (
    <section ref={sectionRef} style={sectionStyle} className="section">
      <div className="container container--lg">
        <p className="section__copy">
          {_.get(props, "section.text", null) &&
            htmlToReact(_.get(props, "section.text", null))}
        </p>
        <div id="thanks" className="section__title" style={{ display: "none" }}>
          {_.get(props, "section.thanks", null)}
        </div>
        <div
          id="thanks_error"
          className="section__title"
          style={{ display: "none" }}
        >
          {_.get(props, "section.thanksError", null)}
        </div>
        <form
          name="contactForm"
          id="contactForm"
          method="POST"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={handleSubmit}
        >
          <div className="sr-only">
            <label id="honeypot-label" htmlFor="honeypot">
              Don't fill this out if you're human:
            </label>
            <input
              aria-labelledby="honeypot-label"
              id="honeypot"
              name="bot-field"
            />
          </div>
          <input
            aria-labelledby="honeypot-label"
            type="hidden"
            name="form-name"
            value="contactForm"
          />
          <div className="form-grid">
            <div className="form-grid__item">
              <div className="form-group">
                <label id="name-label" htmlFor="name">
                  {_.get(props, "section.nameLabel", null)}
                </label>
                <input
                  aria-labelledby="name-label"
                  type="text"
                  name="name"
                  id="name"
                  placeholder={_.get(props, "section.namePlaceholder", null)}
                  required
                />
              </div>
              <div className="form-group">
                <label id="email-label" htmlFor="email">
                  {_.get(props, "section.emailLabel", null)}
                </label>
                <input
                  aria-labelledby="email-label"
                  type="email"
                  name="email"
                  id="email"
                  placeholder={_.get(props, "section.emailPlaceholder", null)}
                  required
                />
              </div>
              <div className="form-group">
                <label id="telephone-label" htmlFor="telephone">
                  {_.get(props, "section.telephoneLabel", null)}
                </label>
                <input
                  aria-labelledby="telephone-label"
                  type="tel"
                  name="telephone"
                  id="telephone"
                  placeholder={_.get(
                    props,
                    "section.telephonePlaceholder",
                    null
                  )}
                  required
                />
              </div>
            </div>
            <div className="form-grid__item">
              <div className="form-group">
                <label id="message-label" htmlFor="message">
                  {_.get(props, "section.messageLabel", null)}
                </label>
                <textarea
                  aria-labelledby="message-label"
                  name="message"
                  id="message"
                  rows="5"
                  placeholder={_.get(props, "section.messagePlaceholder", null)}
                  className="form-text-area"
                />
              </div>
              <div className="form-group">
                <label htmlFor="time">
                  {_.get(props, "section.timeLabel", null)}
                </label>
                <div className="form-select-wrap">
                  <select name="time" id="time">
                    <option value="">
                      {_.get(props, "section.timePlaceholder", null)}
                    </option>
                    {_.get(props, "section.times", null) &&
                      _.get(props, "section.times", null).map((item) => (
                        <option value={item} key={item}>
                          {item}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="form-submit">
            <button type="submit" className="button">
              {_.get(props, "section.button", null)}
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default ContactSection;
