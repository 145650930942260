import React from 'react';
import _ from 'lodash';

import { classNames, withPrefix, markdownify } from '../utils';
import SectionActions from './SectionActions';
import { useStack } from '../hooks/useStack';

const AboutSection = (props) => {
  let section = _.get(props, 'section', null);

  const [sectionRef, sectionStyle] = useStack();

  return (
    <section
      ref={sectionRef}
      style={sectionStyle}
      className={classNames(
        'section section--features',
        _.get(section, 'background', null) &&
          `bg-${_.get(section, 'background', null)}`,
      )}
    >
      {_.get(section, 'title', null) && (
        <div className="container container--lg align-center ">
          <h2 className="section__title section__title--center">
            {_.get(section, 'title', null)}
          </h2>
        </div>
      )}
      <div className="container container--lg overflow-hidden">
        {_.map(_.get(section, 'features', null), (feature, feature_idx) => (
          <div
            key={feature_idx}
            className={classNames('flex', 'flex--middle', 'flex--col-2', {
              'align-center': _.get(feature, 'align', null) === 'center',
              'align-right': _.get(feature, 'align', null) === 'right',
            })}
          >
            <div className="section__body cell animate-fade-in">
              <div className="text-box-border animate-top-in">
                {_.get(feature, 'title', null) &&
                  (_.get(section, 'title', null) ? (
                    <h3
                      className={classNames(
                        'section__title',
                        'section__title--no-border',
                        {
                          'section__title--center':
                            _.get(feature, 'align', null) === 'center',
                          'section__title--right':
                            _.get(feature, 'align', null) === 'right',
                        },
                      )}
                    >
                      {_.get(feature, 'title', null)}
                    </h3>
                  ) : (
                    <h2
                      className={classNames(
                        'section__title',
                        'section__title--no-border',
                        {
                          'section__title--center':
                            _.get(feature, 'align', null) === 'center',
                          'section__title--right':
                            _.get(feature, 'align', null) === 'right',
                        },
                      )}
                    >
                      {_.get(feature, 'title', null)}
                    </h2>
                  ))}
                {_.get(feature, 'content', null) && (
                  <div className="section__copy">
                    {markdownify(_.get(feature, 'content', null))}
                  </div>
                )}
              </div>
              {_.get(feature, 'actions', null) && (
                <div className="section__actions btn-group btn-group--single">
                  <SectionActions
                    {...props}
                    actions={_.get(feature, 'actions', null)}
                  />
                </div>
              )}
            </div>
            {_.get(feature, 'image', null) && (
              <div
                className={classNames(
                  'cell',
                  'section__media',
                  'position-static',
                  'about__image-container',
                  {
                    'section__media--right':
                      _.get(feature, 'image_position', null) === 'right',
                  },
                )}
              >
                <img
                  className="position-absolute about__image"
                  src={withPrefix(_.get(feature, 'image', null))}
                  alt={_.get(feature, 'title', null)}
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </section>
  );
};

export default AboutSection;
