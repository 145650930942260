import _ from 'lodash';
import React from 'react';
import { Helmet } from 'react-helmet';
import { withPrefix } from '../utils';
import Navigation from './Navigation';

const Body = (props) => {
  return (
    <React.Fragment>
      {!props.isPreview && (
        <Helmet>
          <title>
            {_.get(props, 'pageContext.frontmatter.title', null) &&
              _.get(props, 'pageContext.frontmatter.title', null) + ' | '}
            {_.get(props, 'pageContext.site.siteMetadata.title', null)}
          </title>
          <meta charSet="utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initialScale=1.0"
          />
          <meta
            name="description"
            content={
              _.get(props, 'pageContext.frontmatter.excerpt', null) ||
              _.get(props, 'pageContext.site.siteMetadata.description', null)
            }
          />
          <link rel="stylesheet" href={withPrefix('assets/css/main.css')} />
          <link
            rel="icon"
            type="image/svg"
            href="/images/illustrations/btn-1.svg"
          />
        </Helmet>
      )}
      <Navigation></Navigation>
      <div id="site-wrap" className="site">
        <main id="content" className="site-content">
          {props.children}
        </main>
      </div>
    </React.Fragment>
  );
};

export default Body;
