import React from 'react';
import _ from 'lodash';

import { classNames, markdownify } from '../utils';
import SectionActions from './SectionActions';
import { useStack } from '../hooks/useStack';

const CardsSection = (props) => {
  let section = _.get(props, 'section', null);

  const [sectionRef, sectionStyle] = useStack();

  return (
    <section
      ref={sectionRef}
      style={sectionStyle}
      className={classNames(
        'section section--features',
        _.get(section, 'background', null) &&
          `bg-${_.get(section, 'background', null)}`,
      )}
    >
      {_.get(section, 'title', null) && (
        <div className="container container--lg align-center">
          <h2 className="section__title section__title--center">
            {_.get(section, 'title', null)}
          </h2>
        </div>
      )}
      <div className="container container--lg overflow-hidden">
        {_.map(_.get(section, 'cards', null), (feature, feature_idx) => (
          <div className="feature-card cell" key={feature_idx}>
            {_.get(feature, 'title', null) && (
              <div className="feature-card__title-container">
                {_.get(section, 'title', null) ? (
                  <h3 className={classNames('feature-card__title')}>
                    {_.get(feature, 'title', null)}
                  </h3>
                ) : (
                  <h2 className={classNames('feature-card__title')}>
                    {_.get(feature, 'title', null)}
                  </h2>
                )}
                <img
                  className="feature-card__icon"
                  src={_.get(feature, 'icon', null)}
                  alt=""
                />
              </div>
            )}
            {_.get(feature, 'content', null) && (
              <div className="feature-card__content">
                {markdownify(_.get(feature, 'content', null))}
              </div>
            )}
            {_.get(feature, 'actions', null) && (
              <div className="feature-card__actions btn-group">
                <img
                  src="/images/illustrations/arrow.svg"
                  alt=""
                  className="feature-card__arrow"
                />
                <SectionActions
                  {...props}
                  actions={_.get(feature, 'actions', null)}
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </section>
  );
};

export default CardsSection;
