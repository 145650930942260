import { useRef, useEffect, useState } from 'react';

export function useStack() {
  const sectionRef = useRef(null);
  const observerRef = useRef(null);
  const [sectionStyle, setSectionStyle] = useState({});

  useEffect(() => {
    observerRef.current = new ResizeObserver((entries = []) => {
      entries.forEach((entry) => {
        const { offsetHeight } = entry.target;

        const difference = window.innerHeight - offsetHeight;

        if (difference > 0) {
          return;
        }

        setSectionStyle({ top: difference });
      });
    });

    if (sectionRef.current) {
      observerRef.current.observe(sectionRef.current);
    }

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [sectionRef]);

  return [sectionRef, sectionStyle];
}
