import React from 'react';
import _ from 'lodash';

import { getData, withPrefix, markdownify } from '../utils';
import { useStack } from '../hooks/useStack';

const TeamSection = (props) => {
  let section = _.get(props, 'section', null);
  const [sectionRef, sectionStyle] = useStack();

  return (
    <section
      ref={sectionRef}
      style={sectionStyle}
      className="section section--team"
    >
      {_.get(section, 'title', null) && (
        <div className="container container--lg align-center">
          <h2 className="section__title section__title--center">
            {_.get(section, 'title', null)}
          </h2>
        </div>
      )}
      <div className="container container--lg">
        <div className="flex flex--col-3">
          {_.map(_.get(section, 'team', null), (person, person_idx) => {
            let person_data = getData(props.pageContext.site.data, person);
            return (
              <div key={person_idx} className="cell">
                <div className="card team-member">
                  {person_data.photo && (
                    <figure className="card__media card__media--bottom">
                      <img
                        src={withPrefix(person_data.photo)}
                        alt={
                          person_data.first_name +
                          (person_data.last_name
                            ? ' ' + person_data.last_name
                            : '') +
                          ' Photo'
                        }
                      />
                    </figure>
                  )}
                  <div className="card__body">
                    <header className="card__header">
                      <h3 className="h4 card__title">
                        {person_data.first_name} {person_data.last_name}
                      </h3>
                    </header>
                    {person_data.bio && (
                      <div className="card__copy">
                        {markdownify(person_data.bio)}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default TeamSection;
