import React from 'react';
import _ from 'lodash';

import { classNames } from '../utils';
import SectionActions from './SectionActions';
import { useStack } from '../hooks/useStack';

const CtaSection = (props) => {
  let section = _.get(props, 'section', null);

  const [sectionRef, sectionStyle] = useStack();

  return (
    <section
      ref={sectionRef}
      style={sectionStyle}
      className="section section--cta"
    >
      <div className="container container--lg">
        <div
          className={classNames('section__body', 'align-center', {
            'inverse bg-blue':
              _.get(section, 'has_background', null) &&
              _.get(section, 'background_color', null) === 'blue',
            'bg-gray':
              _.get(section, 'has_background', null) &&
              _.get(section, 'background_color', null) === 'gray',
          })}
        >
          <div className="container container--lg">
            {_.get(section, 'title', null) && (
              <h2 className="section__title section__title--center">
                {_.get(section, 'title', null)}
              </h2>
            )}
            {_.get(section, 'subtitle', null) && (
              <div className="section__copy">
                <p>{_.get(section, 'subtitle', null)}</p>
              </div>
            )}
            {_.get(section, 'actions', null) && (
              <div className="section__actions btn-group">
                <SectionActions
                  {...props}
                  actions={_.get(section, 'actions', null)}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default CtaSection;
