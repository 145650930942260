import React from 'react';
import _ from 'lodash';

import { markdownify } from '../utils';
import { useStack } from '../hooks/useStack';

const ContentSection = (props) => {
  const [sectionRef, sectionStyle] = useStack();

  let section = _.get(props, 'section', null);
  return (
    <section ref={sectionRef} style={sectionStyle} className="section">
      <div className="container container--lg">
        {_.get(section, 'title', null) && (
          <h2 className="section__title align-center">
            {_.get(section, 'title', null)}
          </h2>
        )}
        {_.get(section, 'content', null) && (
          <div className="section__copy">
            {markdownify(_.get(section, 'content', null))}
          </div>
        )}
      </div>
    </section>
  );
};

export default ContentSection;
