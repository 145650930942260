import React from "react";
import _ from "lodash";

import { classNames, withPrefix, markdownify } from "../utils";
import SectionActions from "./SectionActions";
import { useStack } from "../hooks/useStack";

const FeaturesSection = (props) => {
  let section = _.get(props, "section", null);

  const [sectionRef, sectionStyle] = useStack();

  return (
    <section
      ref={sectionRef}
      style={sectionStyle}
      className={classNames(
        "section section--features",
        _.get(section, "background", null) &&
          `bg-${_.get(section, "background", null)}`
      )}
    >
      {_.get(section, "title", null) && (
        <div className="container container--lg align-center">
          <h2 className="section__title section__title--center">
            {_.get(section, "title", null)}
          </h2>
        </div>
      )}
      <div className="container container--lg">
        {_.map(_.get(section, "features", null), (feature, feature_idx) => (
          <div
            key={feature_idx}
            className={classNames("flex", "flex--middle", {
              "flex--col-2": _.get(feature, "image", null),
              "align-center": _.get(feature, "align", null) === "center",
              "align-right": _.get(feature, "align", null) === "right",
              "overflow-hidden": _.get(feature, "animate", null),
            })}
          >
            {_.get(feature, "image", null) && (
              <div
                className={classNames("cell", "section__media", {
                  "section__media--right":
                    _.get(feature, "image_position", null) === "right",
                  "section__media--small":
                    _.get(feature, "image_size", null) === "small",
                  "section__media--extra-small":
                    _.get(feature, "image_size", null) === "extra-small",
                  "animate-right-in": _.get(feature, "animate", null),
                })}
              >
                <img
                  src={withPrefix(_.get(feature, "image", null))}
                  alt={_.get(feature, "title", null)}
                  className={classNames(
                    _.get(feature, "animate", null) && "animate-fade-in"
                  )}
                />
              </div>
            )}
            <div className="section__body cell" style={{ maxWidth: "none" }}>
              {_.get(feature, "title", null) &&
                (_.get(section, "title", null) ? (
                  <h3
                    className={classNames("section__title", {
                      "section__title--center":
                        _.get(feature, "align", null) === "center",
                      "section__title--right":
                        _.get(feature, "align", null) === "right",
                      "section__title--no-line": _.get(
                        feature,
                        "is_no_line",
                        null
                      ),
                    })}
                  >
                    {_.get(feature, "title", null)}
                  </h3>
                ) : (
                  <h2
                    className={classNames("section__title", {
                      "section__title--center":
                        _.get(feature, "align", null) === "center",
                      "section__title--right":
                        _.get(feature, "align", null) === "right",
                      "section__title--no-line": _.get(
                        feature,
                        "is_no_line",
                        null
                      ),
                    })}
                  >
                    {_.get(feature, "title", null)}
                  </h2>
                ))}
              {_.get(feature, "content", null) && (
                <div
                  className="section__copy"
                  style={
                    _.get(feature, "not_squeezed", null)
                      ? {}
                      : {
                          maxWidth: 600,
                          marginLeft:
                            _.get(feature, "align", null) === "right"
                              ? "auto"
                              : null,
                        }
                  }
                >
                  {markdownify(_.get(feature, "content", null))}
                </div>
              )}
              {_.get(feature, "actions", null) && (
                <div
                  className={classNames(
                    "section__actions btn-group",
                    _.get(feature, "animate", null) && "animate-fade-in"
                  )}
                >
                  <SectionActions
                    {...props}
                    actions={_.get(feature, "actions", null)}
                  />
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default FeaturesSection;
