import React from 'react';
import _ from 'lodash';

import { getData, getPages } from '../utils';
import BlogFeedItemFilter from './BlogFeedItemFilter';
import { useStack } from '../hooks/useStack';

const BlogFeedSection = (props) => {
  let section = _.get(props, 'section', null);
  let section_author = false;
  let section_category = false;
  let posts_all = getPages(props.pageContext.pages, '/blog');
  let posts_sorted = _.orderBy(posts_all, 'frontmatter.date', 'desc');
  let show_recent = _.get(section, 'show_recent', null);
  let recent_count = _.get(section, 'recent_count', null);
  let post_count = 0;
  if (_.get(section, 'author', null)) {
    section_author = getData(
      props.pageContext.site.data,
      _.get(section, 'author', null),
    );
  }
  if (_.get(section, 'category', null)) {
    section_category = getData(
      props.pageContext.site.data,
      _.get(section, 'category', null),
    );
  }

  const [sectionRef, sectionStyle] = useStack();

  return (
    <section
      ref={sectionRef}
      style={sectionStyle}
      className="section section--posts"
    >
      {_.get(section, 'title', null) && (
        <div className="container container--lg align-center">
          <h2 className="section__title section__title--center">
            {_.get(section, 'title', null)}
          </h2>
        </div>
      )}
      <div className="container container--lg">
        <div className="flex flex--col-3">
          {_.map(posts_sorted, (post, post_idx) => {
            let is_post = _.get(post, 'frontmatter.template', null) === 'post';
            return (
              is_post &&
              (show_recent === false || post_count < recent_count) &&
              (() => {
                post_count = post_count + 1;
                return (
                  <BlogFeedItemFilter
                    key={post_idx}
                    {...props}
                    blog_feed_section={section}
                    post_page={post}
                    section_author={section_author}
                    section_category={section_category}
                  />
                );
              })()
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default BlogFeedSection;
