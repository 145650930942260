import React from 'react';
import _ from 'lodash';

import Action from './Action';

const SectionActions = (props) => {
  let actions = _.get(props, 'actions', null);
  return _.map(actions, (action, action_idx) => (
    <Action key={action_idx} {...props} action={action} />
  ));
};

export default SectionActions;
