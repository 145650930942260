import React from 'react';
import { Link, classNames } from '../utils';

const Navigation = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <>
      <div
        className={classNames('mobile-menu', isOpen && 'mobile-menu--active')}
        onClick={() => setIsOpen(!isOpen)}
      ></div>
      <nav className={classNames('navigation', isOpen && 'navigation--active')}>
        {/* <h2 className="navigation__title">
          <Link href="/">Wendy Nijman</Link>
        </h2> */}
        <Link href="/" className="navigation__logo">
          <img src="/images/logo.png" />
        </Link>
        <div className="navigation__links">
          <div
            style={{
              background: 'var(--light)',
              gridRow: 1,
              gridColumn: 1,
            }}
          ></div>
          <div
            className="navigation__box navigation__box--small"
            style={{ gridRow: '1 / span 2' }}
          >
            <div className="navigation__link-container ">
              <Link className="navigation__link" href="/over">
                Over Mij
              </Link>
              <img
                src="/images/illustrations/btn-1.svg"
                className="navigation__image"
                alt=""
              />
            </div>
            <div className="navigation__wrapper"></div>
          </div>
          <div
            style={{ gridRow: '2 / span 2' }}
            className="navigation__box navigation__box--medium"
          >
            <div className="navigation__link-container ">
              <Link className="navigation__link" href="/tapas">
                TaPas
              </Link>
              <img
                src="/images/illustrations/btn-2.svg"
                className="navigation__image"
                alt=""
              />
            </div>
            <div className="navigation__wrapper"></div>
          </div>
          <div style={{ gridRow: '3 / span 2' }} className="navigation__box">
            <div className="navigation__link-container">
              <Link className="navigation__link" href="/bedrijfsontwikkeling">
                Bedrijfsontwikkeling
              </Link>
              <img
                src="/images/illustrations/btn-3.svg"
                className="navigation__image"
                alt=""
              />
            </div>
            <div className="navigation__wrapper"></div>
          </div>
          <div style={{ gridRow: '4 / span 2' }} className="navigation__box">
            <div className="navigation__link-container">
              <Link className="navigation__link" href="/klantervaringen">
                Klantervaringen
              </Link>
              <img
                src="/images/illustrations/btn-4.svg"
                className="navigation__image"
                alt=""
              />
            </div>
            <div className="navigation__wrapper"></div>
          </div>
          <div
            style={{ gridRow: '5 / span 2' }}
            className="navigation__box navigation__box--small"
          >
            <div className="navigation__link-container">
              <Link className="navigation__link" href="/contact">
                Contact
              </Link>
              <img
                src="/images/illustrations/btn-5.svg"
                className="navigation__image"
                alt=""
              />
            </div>
            <div className="navigation__wrapper"></div>
          </div>
          <div style={{ background: 'var(--light)' }}></div>
          <div
            style={{
              background: 'var(--light)',
              height: '100vh',
              gridColumn: '1 / span 2',
            }}
          ></div>
        </div>
      </nav>
    </>
  );
};

export default Navigation;
